<template>
  <b-overlay rounded="sm" :show="loading">
    <AuthWrapper>
      <template #heading>
        <SecurityLogo />
      </template>
      <template #card>
        <div class="">
          <div class="text-center">
            <h1>Multi-factor Authentication</h1>
          </div>
          <div class="">
            <p v-if="mfa_method === 'app'" class="m-0 p-0 text-center">
              Kindly enter the code from Google Authenticator App
            </p>

            <p v-else class="m-0 p-0 text-center">
              Kindly enter the otp received via {{ mfa_method }}
            </p>
          </div>
        </div>
        <div class="pt-2">
          <validation-observer ref="authenticateForm" #default="{invalid}">
            <b-form @submit.prevent="verify">
              <!--                username -->
              <b-form-group label-for="code">
                <validation-provider
                    #default="{ errors }"
                    name="Code"
                    vid="code"
                    rules="required"
                >
                  <div class="exxtra-input">
                          <span class="leading">
                        <PasswordLock />
                      </span>
                    <input id="login-username" v-model="code" type="number" class="otp"
                           placeholder="* * * * *"
                    >
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div>
                <b-button
                    class="py-1"
                    type="submit"
                    variant="primary"
                    block
                    :disabled="invalid"
                >
                  Verify
                </b-button>
              </div>
            </b-form>
          </validation-observer>
          <p class="text-center mt-2">
            <b-link :to="{ name:'auth-forgot-password' }">
              <feather-icon icon="ChevronLeftIcon" />
              Back to forgot password
            </b-link>
          </p>
        </div>
      </template>
    </AuthWrapper>

  </b-overlay>
</template>

<script>
import EmailIcon from "@/assets/svg/emailIcon.svg";
import PasswordLock from "@/assets/svg/PasswordLock.svg";
import BottomDesign from '@/assets/svg/BottomDesign.svg';
import TurtleRentLogo from "@core/layouts/components/Logo.vue";
import { required } from "@core/utils/validations/validations";
import TextMessageIcon from "@/assets/svg/TextMessageIcon.svg";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import SecurityLogo from "@/pages/auth/shared-components/SecurityLogo.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCol,
  BImg,
  BRow,
  BLink,
  BForm,
  BAlert,
  BButton,
  BOverlay,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BInputGroupAppend
} from "bootstrap-vue";
import { get } from "lodash";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    SecurityLogo,
    AuthWrapper,
    EmailIcon,
    BottomDesign,
    TextMessageIcon,
    PasswordLock,
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // BAlert,
    TurtleRentLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      mfa_method: '',
      loading: false,
      timerString: '00:00',
      code: '',
      // validation rules
      required
    }
  },
  created(){
    this.retrieveMfaMethod();
  },
  methods: {
    async verify() {
      try {
        const payload = {
          token: this.code
        }

        const response = await this.useJwt()
            .mfaService
            .verifyMFAPasswordResetRequest(payload);

        const response_data = response.data.data;
        const { password_reset_token } = response_data;
        this.useJwt()
            .authService
            .setPasswordResetToken(password_reset_token);

        this.useJwt().mfaService.clearMFAPasswordResetToken();

        this.$router.replace({ name: "auth-reset-password" })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Password Reset Request Verified`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `You have successfully verified your password reset request.`,
            },
          })
        })
        .catch(() => {});
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.error = error_message

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    retrieveMfaMethod() {
      const { mfa_method } = this.$route.params;
      if (mfa_method) {
        this.mfa_method = mfa_method;
      } else {
        this.$router.replace({
          name: "auth-forgot-password-mfa",
        })
        .then(() => {})
        .catch(() => {});
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';


</style>
